import React from "react";
import Slider from "react-slick";
import { imageURL, imgFolders } from "../../utils/Constant";

export default function Testimonials(props) {
  var settings = {
    arrows: false,
    dots: true,
    infinite: true, 
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  let testimonials = props.testimonials;
  return (
    <section id="testimonials" className="testimonials">
      <div className="container position-relative">
        <Slider {...settings}>
          {testimonials.map((item, tIdx) => {
              return (
                <div className="testimonial-item" key={tIdx}>
                  <img
                    src={imageURL + imgFolders.testimonials + item.userImg}
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>{item.name}</h3>
                  <h4>{item.designation}</h4>
                  {item.website !== "" && (
                    <h4>
                      <a href={item.website} target="_blank" rel="noreferrer">
                        {item.website}
                      </a>
                    </h4>
                  )}
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    {item.description}
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              );
            })}
        </Slider>
      </div>
    </section>
  );
}
