import { faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { imageURL, imgFolders } from "../../utils/Constant";

export default function AboutMe(props) {
  let info = props.aboutInfo;
  let devStack = props.devStackInfo;
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="section-title">
          <span>About Me</span>
          <h2>About Me</h2>
          <p>{info.description}</p>
        </div>

        <div className="row">
          <div
            className="image col-lg-4 d-flex align-items-stretch justify-content-center justify-content-lg-start"
            style={
              info?.aboutImg
                ? {
                    background: `url(
              ${imageURL + imgFolders.aboutInfo + info.aboutImg}
            )`,
                  }
                : null
            }
          ></div>
          <div className="col-lg-8 d-flex flex-column align-items-stretch">
            <div className="content ps-lg-4 d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Name:</strong>{" "}
                      <span>{props.basicInfo.fullname}</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Phone:</strong> <span>{info.phone}</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>City:</strong> <span>{info.city}</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Freelance:</strong>{" "}
                      <span>
                        {info.freelance ? "Available" : "Not Available"}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Age:</strong> <span>{info.age}</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Degree:</strong> <span>{info.degree}</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Email:</strong> <span>{info.email}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row mt-n4">
                <div className="col-md-6 mt-5 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <FontAwesomeIcon
                      icon={faSmileBeam}
                      style={{ color: "#20b38e" }}
                      className="countI"
                    ></FontAwesomeIcon>
                    <span className="purecounter">
                      {info.projects_involved}
                    </span>
                    <p>
                      <strong>Projects Involved</strong>
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mt-5 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{ color: "#2cbdee" }}
                      className="countI"
                    />
                    <span className="purecounter">
                      {info.years_of_experience}
                    </span>
                    <p>
                      <strong>Years of experience</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="skills-content ps-lg-4">
              {devStack.length > 0 &&
                devStack.map((item, idx) => {
                  return (
                    <div className="progress" key={idx}>
                      <span className="skill">
                        {item.devstack}
                        <i className="val">{item.rating}%</i>
                      </span>
                      <div className="progress-bar-wrap">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${item.rating}%` }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
