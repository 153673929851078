import React from "react";
import parse from "html-react-parser";

export default function Resume(props) {
  let resume = props.resumeInfo;
  let academics = props.academicDatas;
  let experience = props.experienceDatas;
  return (
    <section id="resume" className="resume">
      <div className="container">
        <div className="section-title">
          <span>My Resume</span>
          <h2>My Resume</h2>
          <p>{resume.description}</p>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <h3 className="resume-title">Summary</h3>
            <div className="resume-item pb-0">
              <h4>{props.basicInfo.fullname}</h4>
              <p>
                <em>{resume.summary_desc}</em>
              </p>
              <ul>
                <li>{resume.address}</li>
                <li>{resume.phone}</li>
                <li>{resume.emails}</li>
              </ul>
            </div>
            <h3 className="resume-title">Education</h3>
            {academics.length > 0 &&
              academics.map((item, idx) => {
                return (
                  <div className="resume-item" key={idx}>
                    <h4>{item.title}</h4>
                    <h5>{item.date}</h5>
                    <p>
                      <em>{item.institution}</em>
                    </p>
                    <p>{item.description}</p>
                  </div>
                );
              })}
          </div>
          <div className="col-lg-6">
            <h3 className="resume-title">Professional Experience</h3>
            {experience.length > 0 &&
              experience.map((item, eIdx) => {
                return (
                  <div className="resume-item" key={eIdx}>
                    <h4>{item.title}</h4>
                    <h5>{item.date}</h5>
                    <p>
                      <em>{item.institution}</em>
                    </p>
                    <div>{parse(item.description)}</div>
                    {/* <p>{item.description}</p> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}
