import "./assets/css/main.css";
import Routes from "./routes/Routes";

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
