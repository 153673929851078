import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { imageURL, imgFolders } from "../../utils/Constant";

export default function Header(props) {
  const [scrolled, setScrolled] = useState(false);

  const addBg = () => {
    if (window.scrollY >= 90) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  window.addEventListener("scroll", function () {
    addBg();
  });

  const displayMobileMenu = () => {
    document.getElementById("navbar").classList.toggle("navbar-mobile");
    let mobileM = document.getElementById("mobileMenu");
    mobileM.classList.toggle("bi-list");
    mobileM.classList.toggle("bi-x");
  };

  const selectMenu = (e) => {
    let menus = document.querySelectorAll(".nav-link");
    for (let i = 0; i < menus.length; i++) {
      menus[i].classList.remove("active");
    }
    e.target.classList.add("active");
    if (document.getElementById("navbar").classList.contains("navbar-mobile")) {
      displayMobileMenu();
    }
  };

  return (
    <>
      <header
        className={
          "fixed-top d-flex justify-content-center align-items-center header-transparent " +
          (scrolled ? "header-scrolled" : "")
        }
        id="header"
      >
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a className="nav-link active" href="#hero" onClick={selectMenu}>
                Home
              </a>
            </li>
            <li>
              <a className="nav-link" href="#about" onClick={selectMenu}>
                About
              </a>
            </li>
            <li>
              <a className="nav-link" href="#resume" onClick={selectMenu}>
                Resume
              </a>
            </li>
            <li>
              <a className="nav-link" href="#contact" onClick={selectMenu}>
                Contact
              </a>
            </li>
          </ul>
          <i
            className="bi bi-list mobile-nav-toggle"
            onClick={displayMobileMenu}
            id="mobileMenu"
          ></i>
        </nav>
      </header>
      <section
        id="hero"
        style={
          props.basicInfo?.bannerImg
            ? {
                background: `url(
            ${imageURL + imgFolders.basicInfo + props.basicInfo.bannerImg}
          )`,
              }
            : null
        }
      >
        <div className="hero-container">
          <h1>
            {props.basicInfo?.designation ? props.basicInfo.designation : ""}{" "}
            {props.basicInfo?.fullname ? props.basicInfo.fullname : ""}
          </h1>
          <h2>
            {props.basicInfo?.description ? props.basicInfo.description : ""}
          </h2>
          {props.basicInfo?.resumeFile ? (
            <a
              href={
                imageURL + imgFolders.basicInfo + props.basicInfo.resumeFile
              }
              download
              className="downloadBtn"
              target="_blank"
              rel="noreferrer"
            >
              Download CV
            </a>
          ) : null}

          <a
            href="#about"
            className="btn-scroll scrollto mt-3"
            title="Scroll Down"
          >
            <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
          </a>
        </div>
      </section>
    </>
  );
}
