import React, { Component } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage";
import PortfolioPage from "../pages/PortfolioPage";

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" element={<PortfolioPage />} />
        <Route path="*" Component={ErrorPage} />
      </Switch>
    );
  }
}

export default Routes;
