import React from "react";
import { imageURL, imgFolders } from "../../utils/Constant";

export default function Footer(props) {
  return (
    <footer
      id="footer"
      style={
        props.basicInfo?.footerBg
          ? {
              background: `url(
            ${imageURL + imgFolders.basicInfo + props.basicInfo.footerBg}
          )`,
            }
          : null
      }
    >
      <div className="container">
        <h3>
          {props.basicInfo?.designation ? props.basicInfo.designation : ""}{" "}
          {props.basicInfo?.fullname ? props.basicInfo.fullname : ""}
        </h3>
        <p>{props.basicInfo?.footer_text ? props.basicInfo.footer_text : ""}</p>
        <div className="social-links">
          <a
            href={
              props.basicInfo?.facebook_link &&
              props.basicInfo.facebook_link !== ""
                ? props.basicInfo.facebook_link
                : "#"
            }
            className="facebook"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-facebook"></i>
          </a>
          <a
            href={
              props.basicInfo?.insta_link && props.basicInfo.insta_link !== ""
                ? props.basicInfo.insta_link
                : "#"
            }
            className="instagram"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-instagram"></i>
          </a>
          <a
            href={
              props.basicInfo?.linkedIn_link &&
              props.basicInfo.linkedIn_link !== ""
                ? props.basicInfo.linkedIn_link
                : "#"
            }
            className="linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-linkedin"></i>
          </a>
        </div>
        <div className="copyright">
          {props.basicInfo?.copyright_text &&
          props.basicInfo.copyright_text !== ""
            ? props.basicInfo.copyright_text
            : ""}
        </div>
      </div>
    </footer>
  );
}
