// export const serverlocation = "http://localhost:8000/";

//in producation
export const serverlocation = "https://sandeepshakya.com.np/portfolio_backend/";
export const imageURL = serverlocation + "uploads/";
export const apiURL = serverlocation + "api/";
export const URL = {
  getAllData: apiURL + "getAllData",
  sendMessage: apiURL + "sendMail",
};

export const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "User-Agent": window.navigator.userAgent,
};

export const imgFolders = {
  basicInfo: "basicInfos/",
  aboutInfo: "aboutInfos/",
  testimonials: "testimonials/",
};
