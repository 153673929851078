import React, { Component } from "react";
import { WithRouter } from "../utils/WithRouter";
import Theme1 from "../components/theme1/Theme1";
import axios from "axios";
import { headers, URL } from "../utils/Constant";
import Swal from "sweetalert2";

class PortfolioPage extends Component {
  state = {
    basicInfo: null,
    aboutInfo: null,
    devStackInfo: [],
    resumeInfo: null,
    academicDatas: [],
    experienceDatas: [],
    testimonials: [],
  };

  componentDidMount() {
    this.getAllData();
  }

  getAllData = () => {
    axios
      .get(URL.getAllData, headers)
      .then((response) => {
        if (response.status === 200) {
          let datum = response.data.data;
          this.setState({
            basicInfo: datum.basicInfo,
            aboutInfo: datum.aboutInfo,
            devStackInfo: datum.devStackInfo,
            resumeInfo: datum.resumeInfo,
            academicDatas: datum.academicDatas,
            experienceDatas: datum.experienceDatas,
            testimonials: datum.testimonials,
          });
        }
      })
      .catch((err) => {
        Swal.fire(err);
      });
  };

  render() {
    return (
      <div>
        <Theme1
          basicInfo={this.state.basicInfo}
          aboutInfo={this.state.aboutInfo}
          devStackInfo={this.state.devStackInfo}
          resumeInfo={this.state.resumeInfo}
          academicDatas={this.state.academicDatas}
          experienceDatas={this.state.experienceDatas}
          testimonials={this.state.testimonials}
        ></Theme1>
      </div>
    );
  }
}

export default WithRouter(PortfolioPage);
