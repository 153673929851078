import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { URL, headers } from "../../utils/Constant";
import CustomFullSpinner from "../../utils/CustomFullSpinner";

export default function ContactMe(props) {
  let basic = props.basicInfo;
  let about = props.aboutInfo;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "subject":
        setSubject(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const sendMessage = () => {
    if (name === "" || email === "" || subject === "" || message === "") {
      Swal.fire(
        "Missing Data",
        "Please provide all the necessary data",
        "error"
      );
    } else {
      let data = {
        name: name,
        email: email,
        subject: subject,
        message: message,
      };
      setLoading(true);
      axios
        .post(URL.sendMessage, data, headers)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            Swal.fire(
              "Success",
              "Your message has been successfully sent. I will contact you soon. Thank you.",
              "success"
            );
            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
          }
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire("Error", err.response?.data?.message, "error");
        });
    }
  };

  return (
    <section id="contact" className="contact mb-5">
      {loading ? (
        <CustomFullSpinner text="Sending your message..."></CustomFullSpinner>
      ) : null}
      <div className="container">
        <div className="section-title">
          <span>Contact Me</span>
          <h2>Contact Me</h2>
          <p>
            Ready to discuss your next project? Drop me a line and let's make it
            happen!
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="info-box">
                  <i className="bx bx-share-alt"></i>
                  <h3>Social Profiles</h3>
                  <div className="social-links">
                    <a
                      href={
                        basic.facebook_link !== "" ? basic.facebook_link : "#"
                      }
                      className="facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a
                      href={basic.insta_link !== "" ? basic.insta_link : "#"}
                      className="instagram"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>

                    <a
                      href={
                        basic.linkedIn_link !== "" ? basic.linkedIn_link : "#"
                      }
                      className="linkedin"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Me</h3>
                  <p>{about.email}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Me</h3>
                  <p>{about.phone}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Your Name"
                  value={name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Your Email"
                  onChange={handleChange}
                  required
                  value={email}
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Subject"
                onChange={handleChange}
                required
                value={subject}
              />
            </div>
            <div className="form-group mt-3">
              <textarea
                className="form-control"
                name="message"
                rows="6"
                placeholder="Message"
                onChange={handleChange}
                required
                value={message}
              ></textarea>
            </div>
            <div className="text-center mt-3">
              <button className="contactBtn" onClick={sendMessage}>
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
