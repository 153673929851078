import React from "react";
import Header from "./Header";
import AboutMe from "./AboutMe";
import Resume from "./Resume";
import Testimonials from "./Testimonials";
import ContactMe from "./ContactMe";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Theme1(props) {
  return (
    <>
      <Header basicInfo={props.basicInfo}></Header>
      <main id="main">
        {props?.aboutInfo ? (
          <AboutMe
            aboutInfo={props.aboutInfo}
            basicInfo={props.basicInfo}
            devStackInfo={props.devStackInfo}
          ></AboutMe>
        ) : null}
        {props?.resumeInfo ? (
          <Resume
            resumeInfo={props.resumeInfo}
            basicInfo={props.basicInfo}
            academicDatas={props.academicDatas}
            experienceDatas={props.experienceDatas}
          ></Resume>
        ) : null}
        {props?.testimonials && props.testimonials.length > 0 ? (
          <Testimonials testimonials={props.testimonials}></Testimonials>
        ) : null}
        {props?.aboutInfo && (
          <ContactMe
            basicInfo={props.basicInfo}
            aboutInfo={props.aboutInfo}
          ></ContactMe>
        )}
      </main>
      <Footer basicInfo={props.basicInfo}></Footer>
    </>
  );
}
